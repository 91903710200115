import React from 'react';
import Title from '../title'
import Card from '../card'

export default function blogPage(props) {
    return (
        <Card>
            <Title>Title</Title>
            <p>Description Text</p>
        </Card>
    )
};